import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Theme,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import useSetting from "../hooks/use-setting";
import Layout from "../components/layouts";
import { makeStyles } from "@material-ui/styles";
import { useHistory, Link } from "react-router-dom";
import PencilIcon from "../assets/img_pencil.svg";
import useXSmallWidthMediaQuery from "../hooks/use-xsmall-width-mq";
import useXSmallHeightMediaQuery from "../hooks/use-xsmall-height-mq";
import { useEffectOnce } from "react-use";
import { useSnackbar } from "material-ui-snackbar-provider";
import { Helmet } from "react-helmet";
import useConfig from "../hooks/use-config";
import { OilHeatSettingsData } from "../types/airgraft";
import { addLog } from "../services/logs";
import useAuth from "../hooks/use-auth";

const useStyles = makeStyles((theme: Theme) => ({
  "@global": {
    "@keyframes rainbowGradient": {
      "0%": {
        backgroundPosition: "0% 0%"
      },
      "50%": {
        backgroundPosition: "50% 0%"
      },
      "100%": {
        backgroundPosition: "100% 0%"
      }
    }
  },
  copyURLTitle: {
    // fontWeight: 400,
    fontFamily: "Roobert",
    fontSize: "15px",
    [theme.breakpoints.down(375)]: {
      fontSize: "12px"
    },
    color: "#888",
    lineHeight: 1.2,
    textAlign: "left"
  },
  content: {
    fontWeight: 400,
    fontFamily: "Roobert",
    fontSize: "14px",
    lineHeight: 1.25,
    textAlign: "left"
  },
  summary: {
    borderRadius: "5px",
    overflow: "hidden"
  },
  summaryTitleBox: {
    height: "36px",
    background: "#FFFFFF",
    borderBottom: "1px solid #F2F2F2",
    alignItems: "center",
    justifyContent: "center"
  },
  summaryTitle: {
    fontWeight: 600,
    fontFamily: "Roobert",
    fontSize: "14px",
    letterSpacing: "0px"
  },
  summaryBox: {
    background: "#FFFFFF",
    paddingBottom: "10px"
  },
  summaryValue: {
    fontWeight: 400,
    fontFamily: "Roobert",
    fontSize: "14px",
    letterSpacing: "0px"
  },
  summaryValueMono: {
    fontWeight: 400,
    fontFamily: "'Menlo', monospace",
    fontSize: "13px",
    letterSpacing: "0px"
  },
  urlBox: {
    display: "flex",
    flexDirection: "row",
    borderRadius: "5px",
    overflow: "hidden",
    height: "40px",
    background: "#FFFFFF",
    alignItems: "center",
    justifyContent: "center"
  },
  url: {
    fontWeight: 400,
    fontFamily: "Roobert",
    fontSize: "14px",
    lineHeight: 1.25,
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  urlButton: {
    fontWeight: 600,
    fontFamily: "Roobert",
    fontSize: "12px",
    letterSpacing: "0.5px",
    [theme.breakpoints.down(375)]: {
      fontSize: "12px",
      letterSpacing: "0px",
    },
    height: 24,
    textAlign: "center",
    borderRadius: "50px",
    textTransform: "uppercase",
    color: "#1E1E1E",
    background: "#E4E4E4",
  },
  deleteButton: {
    fontWeight: 400,
    fontFamily: "Roobert",
    fontSize: "12px",
    letterSpacing: "0.5px",
    [theme.breakpoints.down(375)]: {
      fontSize: "12px",
      letterSpacing: "0px",
    },
    height: 28,
    paddingLeft: 16,
    paddingRight: 16,
    textAlign: "center",
    borderRadius: "50px",
    textTransform: "uppercase",
    color: "#F00",
    "&:hover": {
      color: "#FFF",
      background: "#F00",
    }
  },
  rowsContainer: {
    display: "flex",
    flexWrap: "wrap"
  },
  fullRow: {
    flexBasis: "100%"
  },
  halfRowLeft: {
    flexBasis: "50%",
    paddingRight: theme.spacing(1)
  },
  halfRowRight: {
    flexBasis: "50%",
    paddingLeft: theme.spacing(1)
  },
  rainbow: {
    fontWeight: 600,
    fontFamily: "Roobert",
    fontSize: "12px",
    letterSpacing: "1px",
    [theme.breakpoints.down(375)]: {
      fontSize: "12px",
      letterSpacing: "0px",
    },
    height: 36,
    background: "linear-gradient(90deg, #FF0000, #FFEA29, #55FF5C, #00C2FF, #FA00FF, #FF0000)",
    backgroundSize: "1000%",
    animation: "rainbowGradient 2s linear infinite",
    borderRadius: "50px",
    textTransform: "uppercase",
  },
  fillHeight: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flex: 1,
    justifyContent: "center"
  },
  bottomBar: {
    position: "fixed",
    left: "0",
    right: "0",
    bottom: "0",
    transform: "translate(calc(50vw - 50%))",
    maxWidth: '100vw',
    minWidth: '320px',
    [theme.breakpoints.up("sm")]: {
      maxWidth: '600px',
    },
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down(375)]: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    display: "flex",
    flexWrap: "wrap",
  },
  bottomBarBackground: {
    borderTop: "1px solid #DDD",
    backgroundColor: "#F3F0E9"
  },
  editButton: {
    fontWeight: 600,
    fontFamily: "Roobert",
    fontSize: "12px",
    letterSpacing: "1px",
    [theme.breakpoints.down(375)]: {
      fontSize: "12px",
      letterSpacing: "0px",
    },
    height: 36,
    textAlign: "center",
    borderRadius: "50px",
    textTransform: "uppercase",
    color: "#000",
    backgroundColor: "#FFF",
    "&:hover": {
      background: "#DDD",
    }
  },
	loading: {
		display: "flex",
		flex: "1 1 auto",
		width: "100%",
		height: "320px",
		justifyContent: "center",
		alignItems: "center",
		padding: theme.spacing(3)
	},
  deleteLoading: {
    visibility: 'visible',
    display: 'flex'
  },
}));

function OilProfilePage() {
  const isXSmallWidth = useXSmallWidthMediaQuery();
  const isXSmallHeight = useXSmallHeightMediaQuery();
  const classes = useStyles();
  const history = useHistory();
  const snackbar = useSnackbar();
  const { id, brand, oilName, oilType, oilStrain, terpenes, htStep, hpStep, ppStep, cfStep, isLocked, update, encodeRGB, sharedUrl, importFromUrl } = useSetting();
  const { tt, ht, hp, pp, cf } = useConfig();
  const { email } = useAuth();
  const [rightTitle, setRightTitle] = useState<string>("");
  const [isTogglingLock, setIsTogglingLock] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isShowingDeleteAlert, setIsShowingDeleteAlert] = React.useState(false);
  const isAdvMode = process.env.REACT_APP_ENV === "adv";
  const handleConfirmDeleteAlert = () => {
    if (!email) {
      return
    }
    setIsDeleting(true)
    const htValue = isAdvMode ? htStep : ht[htStep]
    const hpValue = isAdvMode ? hpStep : hp[hpStep]
    const ppValue = isAdvMode ? ppStep : pp[ppStep]
    const cfValue = isAdvMode ? cfStep : cf[cfStep]
    addLog(
      id,
      email,
      brand,
      oilName,
      oilType,
      oilStrain,
      `${terpenes?.toFixed(0) ?? 0}%`,
      `${htStep + 1}=${(1 + 0.1 * htValue).toFixed(1)}s(${htValue})`,
      `${hpStep + 1}=${(7.25 + 0.25 * hpValue).toFixed(2)}w(${hpValue})`,
      `${ppStep + 1}=${5 + 5 * ppValue}%(${ppValue})`,
      `${cfStep + 1}=${(0.1 + 0.1 * cfValue).toFixed(1)}(${cfValue})`,
      `${window.location.origin}${sharedUrl()}`,
      "delete",
      isLocked ? "1" : "",
      "1"
    ).then(() => {
      setIsDeleting(false)
      setIsShowingDeleteAlert(false);
      history.push("/user")
    })
  };
  const handleRightButton = () => {
    if (!email) {
      return
    }
    setIsTogglingLock(true)
    updateRightButton(isLocked, true, email)
    const htValue = isAdvMode ? htStep : ht[htStep]
    const hpValue = isAdvMode ? hpStep : hp[hpStep]
    const ppValue = isAdvMode ? ppStep : pp[ppStep]
    const cfValue = isAdvMode ? cfStep : cf[cfStep]
    const newLocked = isLocked ? false : true
    addLog(
      id,
      email,
      brand,
      oilName,
      oilType,
      oilStrain,
      `${terpenes?.toFixed(0) ?? 0}%`,
      `${htStep + 1}=${(1 + 0.1 * htValue).toFixed(1)}s(${htValue})`,
      `${hpStep + 1}=${(7.25 + 0.25 * hpValue).toFixed(2)}w(${hpValue})`,
      `${ppStep + 1}=${5 + 5 * ppValue}%(${ppValue})`,
      `${cfStep + 1}=${(0.1 + 0.1 * cfValue).toFixed(1)}(${cfValue})`,
      `${window.location.origin}${sharedUrl()}`,
      isLocked ? "unlock" : "lock",
      newLocked ? "1" : ""
    ).then(() => {
      const isLocked = newLocked
      update({ isLocked } as OilHeatSettingsData, () => {
        setIsTogglingLock(false)
        updateRightButton(isLocked, false, id)
      })
    })
  };
  const handleApplyToPod = () => {
    history.push(`/activation?rgbks=${encodeRGB(email)}`);
  };
  const updateRightButton = (isLocked: boolean, isToggling: boolean, uuid?: string) => {
    if (uuid) {
      setRightTitle(`${isLocked ? `Unlock${isToggling ? "ing..." : ""}` : `Lock${isToggling ? "ing..." : ""}`}`)
    } else {
      setRightTitle("")
    }
  };
  useEffectOnce(() => {
    importFromUrl((data?: OilHeatSettingsData) => {
      if (!data) {
        setRightTitle("")
        return
      }
      let { id, isLocked } = data
      updateRightButton(isLocked, false, id)
    })
  })


  return (
    <Layout title="Oil Profile" 
            showBack={true}
            rightTitle={`${rightTitle}`}
            rightAction={handleRightButton}>
      <Helmet>
        <title>Oil Profile</title>
      </Helmet>
      {isTogglingLock && (
        <Box className={classes.loading}>
          <CircularProgress />
        </Box>
      )}
      {!isTogglingLock && (
        <Box>
          <Box mt={isXSmallHeight ? 1 : 3} className={classes.summary}>
            <Box display="flex" flexDirection="row" className={classes.summaryTitleBox}>
              <Box display="flex" flexDirection="row" flexGrow={1} flex={1} alignItems="center">
                <Box ml={isXSmallWidth ? 1 : 2} height="22px">
                  <Typography className={classes.summaryTitle}>
                    Oil Name: {oilName ?? "N/A"}
                  </Typography>
                </Box>
              </Box>
              {(!isLocked && id !== undefined) && (
                <Box display="flex" flexDirection="row" flexShrink={1}>
                  <IconButton
                    component={Link}
                    to="/oil"
                  >
                    <img src={PencilIcon} alt="pencil icon" style={{ opacity: 0.5 }}/>
                  </IconButton>
                </Box>
              )}
            </Box>
            <Box className={classes.summaryBox}>
              <Box className={classes.rowsContainer}>
                <Box className={classes.fullRow}>
                  <Box ml={isXSmallWidth ? 1 : 2} mt="10px" display="flex" flexDirection="row">
                    <Box flexGrow={1} flex={1}>
                      <Typography className={classes.summaryValue} style={{ color: "#7E7E7E" }}>
                        Oil Type
                      </Typography>
                    </Box>
                    <Box ml={isXSmallWidth ? 1 : 2} mr={isXSmallWidth ? 1 : 2} flexShrink={1}>
                      <Typography className={classes.summaryValue}>
                        {oilType ?? "N/A"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.fullRow}>
                  <Box ml={isXSmallWidth ? 1 : 2} mt="5px" display="flex" flexDirection="row">
                    <Box flexGrow={1} flex={1}>
                      <Typography className={classes.summaryValue} style={{ color: "#7E7E7E" }}>
                        Oil Strain
                      </Typography>
                    </Box>
                    <Box ml={isXSmallWidth ? 1 : 2} mr={isXSmallWidth ? 1 : 2} flexShrink={1}>
                      <Typography className={classes.summaryValue}>
                        {oilStrain ?? "N/A"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                { isAdvMode ? (<Box />) : (
                  <Box className={classes.fullRow}>
                    <Box ml={isXSmallWidth ? 1 : 2} mt="5px" display="flex" flexDirection="row">
                      <Box flexGrow={1} flex={1}>
                        <Typography className={classes.summaryValue} style={{ color: "#7E7E7E" }}>
                          Terpenes
                        </Typography>
                      </Box>
                      <Box ml={isXSmallWidth ? 1 : 2} mr={isXSmallWidth ? 1 : 2} flexShrink={1}>
                        <Typography className={classes.summaryValue}>
                          {terpenes?.toFixed(0) ?? 0}%
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box mt={isXSmallHeight ? 1 : 3} className={classes.summary}>
            <Box display="flex" flexDirection="row" className={classes.summaryTitleBox}>
              <Box display="flex" flexDirection="row" flexGrow={1} flex={1} alignItems="center" >
                <Box ml={isXSmallWidth ? "8px" : "12px"} height="22px">
                  <Typography className={classes.summaryTitle}>
                    { isAdvMode ? "Heat Settings" : "Temperature Settings"}
                  </Typography>
                </Box>
              </Box>
              {(!isLocked && id !== undefined) && (
                <Box flexShrink={1}>
                  <IconButton
                    component={Link}
                    to="/configure"
                  >
                    <img src={PencilIcon} alt="pencil icon" style={{ opacity: 0.5 }}/>
                  </IconButton>
                </Box>
              )}
            </Box>
            <Box className={classes.summaryBox}>
              <Box className={classes.rowsContainer}>
                <Box className={classes.fullRow}>
                  <Box ml={isXSmallWidth ? 1 : 2} mt="10px" display="flex" flexDirection="row">
                    <Box flexGrow={1} flex={1}>
                      <Typography className={classes.summaryValue} style={{ color: "#7E7E7E" }}>
                        {isAdvMode ? "Heating Time" : tt[0]}
                      </Typography>
                    </Box>
                    <Box ml={isXSmallWidth ? 1 : 2} mr={isXSmallWidth ? 1 : 2} flexShrink={1}>
                      <Typography className={classes.summaryValueMono}>
                        { isAdvMode ? `${(1.0 + 0.1 * (htStep ?? 0)).toFixed(1)}s` : `${(htStep ?? 0) + 1}` }
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.fullRow}>
                  <Box ml={isXSmallWidth ? 1 : 2} mt="3px" display="flex" flexDirection="row">
                    <Box flexGrow={1} flex={1}>
                      <Typography className={classes.summaryValue} style={{ color: "#7E7E7E" }}>
                        {isAdvMode ? "Heating Power" : tt[1]}
                      </Typography>
                    </Box>
                    <Box ml={isXSmallWidth ? 1 : 2} mr={isXSmallWidth ? 1 : 2} flexShrink={1}>
                      <Typography className={classes.summaryValueMono}>
                        { isAdvMode ? `${(7.25 + 0.25 * (hpStep ?? 0)).toFixed(2)}W` : `${(hpStep ?? 0) + 1}` }
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.fullRow}>
                  <Box ml={isXSmallWidth ? 1 : 2} mt="3px" display="flex" flexDirection="row">
                    <Box flexGrow={1} flex={1}>
                      <Typography className={classes.summaryValue} style={{ color: "#7E7E7E" }}>
                        {isAdvMode ? "Plateau Power" : tt[2]}
                      </Typography>
                    </Box>
                    <Box ml={isXSmallWidth ? 1 : 2} mr={isXSmallWidth ? 1 : 2} flexShrink={1}>
                      <Typography className={classes.summaryValueMono}>
                        { isAdvMode ? `${(5 + 5 * (ppStep ?? 0)).toFixed(0)}%` : `${(ppStep ?? 0) + 1}` }
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.fullRow}>
                  <Box ml={isXSmallWidth ? 1 : 2} mt="3px" display="flex" flexDirection="row">
                    <Box flexGrow={1} flex={1}>
                      <Typography className={classes.summaryValue} style={{ color: "#7E7E7E" }}>
                        {isAdvMode ? "Cooling Factor" : tt[3]}
                      </Typography>
                    </Box>
                    <Box ml={isXSmallWidth ? 1 : 2} mr={isXSmallWidth ? 1 : 2} flexShrink={1}>
                      <Typography className={classes.summaryValueMono}>
                        { isAdvMode ? `${(0.1 + 0.1 * (cfStep ?? 0)).toFixed(1)}` : `${(cfStep ?? 0) + 1}` }
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          { isAdvMode ? <Box/> : (
            <Box mt={isXSmallHeight ? 1 : 3}>
              <Box ml={0.2} mr={0.2}>
                <Typography className={classes.copyURLTitle}>
                  Copy URL to share your settings
                </Typography>
              </Box>
              <Box mt={0.5} className={classes.urlBox} display="flex" flexDirection="row">
                <Box ml={1} mr={1} flexGrow={1} flex={1} height="24px" textOverflow="ellipsis">
                  <TextField
                    value={`${window.location.origin}${sharedUrl()}`}
                    InputLabelProps={{
                      style: { fontSize: isXSmallWidth ? "0.9rem" : "1rem" }
                    }}
                    fullWidth
                    size="small"
                    inputProps={{ style: { fontSize: isXSmallWidth ? "0.9rem" : "1rem", textOverflow: "ellipsis" } }}
                    InputProps={{ disableUnderline: true }}
                  />
                </Box>
                <Box mr={1} flexShrink={1}>
                  <Button
                    variant="contained"
                    // fullWidth
                    color="secondary"
                    className={classes.urlButton}
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    onClick={() => {
                      navigator.clipboard.writeText(`${window.location.origin}${sharedUrl()}`)
                      snackbar.showMessage("Link copied to clipboard!")
                    }}
                  >
                    Copy url
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
          {(!isLocked && id !== undefined) && (
            <Box>
              <Box height={32} />
              <Box style={{display: "flex", justifyContent: "center"}}>
                <Button
                  variant="text"
                  // fullWidth
                  className={classes.deleteButton}
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  onClick={() => {
                    setIsShowingDeleteAlert(true);
                  }}
                >
                  Delete Profile
                </Button>
                <Dialog
                  open={isShowingDeleteAlert}
                  onClose={(event, reason) => {
                    console.log(reason)
                    if (reason !== 'backdropClick' || isDeleting === false) {
                      setIsShowingDeleteAlert(false);
                    }
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    Delete Oil Profile?
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Oil Profile "{oilName}" will be deleted.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    {
                      isDeleting && 
                      (<Box className={classes.deleteLoading}>
                        <CircularProgress size={20}/>
                      </Box>)
                    }
                    <Button disabled={isDeleting} autoFocus onClick={() => {
                        setIsShowingDeleteAlert(false);
                    }}>
                      Cancel
                    </Button>
                    <Button onClick={handleConfirmDeleteAlert} disabled={isDeleting}>
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
              <Box height={32} />
            </Box>
          )}
          <Box height={isXSmallHeight ? 64 : 88} />
          <Box height={isXSmallHeight ? 52 : 68} className={`${classes.bottomBarBackground} ${classes.bottomBar}`} />
          <Box className={classes.bottomBar} mt={isXSmallHeight ? 1 : 2} mb={isXSmallHeight ? 1 : 2}>
            <Box mr={1} style={{ flexBasis: (isLocked || id === undefined) ? "100%" : "75%" }}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleApplyToPod}
                className={classes.rainbow}
                disableElevation
                disableFocusRipple
                disableRipple
              >
                apply to pod
              </Button>
            </Box>
            {(!isLocked && id !== undefined) && (
              <Box style={{ flexGrow: 1 }}>
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.editButton}
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  onClick={() => {
                    history.push(`/configure`);
                  }}
                >
                  edit
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Layout>
  );
}

export default OilProfilePage;