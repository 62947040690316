import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Theme,
  CircularProgress
} from "@material-ui/core";
import useConfig from "../hooks/use-config";
import useSetting from "../hooks/use-setting";
import Layout from "../components/layouts";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import { OilHeatSettingsData } from "../types/airgraft";
import CustomSlider from "../components/CustomSlider"
import { Helmet } from "react-helmet";
import { addLog } from "../services/logs";
import useXSmallHeightMediaQuery from "../hooks/use-xsmall-height-mq";
import useXSmallWidthMediaQuery from "../hooks/use-xsmall-width-mq";
import { useEffectOnce } from "react-use";
import useAuth from "../hooks/use-auth";
import { isValidEmail } from "../helpers/validateEmail";

const useStyles = makeStyles((theme: Theme) => ({
  "@global": {
    "@keyframes rainbowGradient": {
      "0%": {
        backgroundPosition: "0% 0%"
      },
      "50%": {
        backgroundPosition: "50% 0%"
      },
      "100%": {
        backgroundPosition: "100% 0%"
      }
    }
  },
  justifyContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center"
  },
  heatSettingsTitle: {
    paddingLeft: "5px",
    fontWeight: 600,
    fontFamily: "Reckless",
    fontSize: "18px",
    [theme.breakpoints.down(375)]: {
      fontSize: "15px"
    },
    lineHeight: 1.2
  },
  content: {
    fontWeight: 400,
    fontFamily: "Roobert",
    fontSize: "14px",
    lineHeight: 1.25
  },
  card: {
    borderRadius: "5px",
    background: "#FFFFFF",
  },
  cardTitle: {
    fontWeight: 400,
    fontFamily: "Roobert",
    fontSize: "18px",
    [theme.breakpoints.down(375)]: {
      fontSize: "15px"
    },
    letterSpacing: "0px"
  },
  cardContent: {
    fontWeight: 400,
    fontFamily: "Roobert",
    fontSize: "11px",
    letterSpacing: "0px"
  },
  slider: {
    paddingLeft: "4px",
    paddingRight: "4px",
  },
  sliderValue: {
    fontSize: 13,
    fontFamily: "'Menlo', monospace",
    letterSpacing: "0.5px",
    textAlign: "right",
    color: "#ACA9A3"
  },
  sliderFooter: {
    fontWeight: 400,
    fontSize: 13,
    letterSpacing: "1.5px",
    textTransform: "uppercase",
  },
  rowsContainer: {
    display: "flex",
    flexWrap: "wrap"
  },
  fullRow: {
    flexBasis: "100%"
  },
  halfRowLeft: {
    flexBasis: "50%",
    paddingRight: theme.spacing(1)
  },
  halfRowRight: {
    flexBasis: "50%",
    paddingLeft: theme.spacing(1)
  },
  rainbow: {
    fontWeight: 600,
    fontFamily: "Roobert",
    fontSize: "12px",
    letterSpacing: "1px",
    [theme.breakpoints.down(375)]: {
      fontSize: "12px",
      letterSpacing: "0px",
    },
    height: 36,
    background: "linear-gradient(90deg, #FF0000, #FFEA29, #55FF5C, #00C2FF, #FA00FF, #FF0000)",
    backgroundSize: "1000%",
    animation: "rainbowGradient 2s linear infinite",
    borderRadius: "50px",
    textTransform: "uppercase",
  },
  fillHeight: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flex: 1,
    justifyContent: "center"
  },
  bottomBar: {
    position: "fixed",
    left: "0",
    right: "0",
    bottom: "0",
    transform: "translate(calc(50vw - 50%))",
    maxWidth: '100vw',
    minWidth: '320px',
    [theme.breakpoints.up("sm")]: {
      maxWidth: '600px',
    },
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down(375)]: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    display: "flex",
    flexWrap: "wrap",
  },
  bottomBarBackground: {
    borderTop: "1px solid #DDD",
    backgroundColor: "#F3F0E9"
  },
  saveButton: {
    fontWeight: 600,
    fontFamily: "Roobert",
    fontSize: "12px",
    letterSpacing: "1px",
    [theme.breakpoints.down(375)]: {
      fontSize: "12px",
      letterSpacing: "0px",
    },
    height: 36,
    textAlign: "center",
    borderRadius: "50px",
    textTransform: "uppercase",
    color: "#000",
    backgroundColor: "#FFF",
    "&:hover": {
      background: "#DDD",
    }
  },
  summary: {
    borderRadius: "5px",
    overflow: "hidden"
  },
  summaryTitleBox: {
    height: "36px",
    background: "#FFFFFF",
    borderBottom: "1px solid #F2F2F2",
    alignItems: "center",
    justifyContent: "center"
  },
  summaryTitle: {
    fontWeight: 600,
    fontFamily: "Roobert",
    fontSize: "14px",
    letterSpacing: "0px"
  },
  summaryBox: {
    background: "#FFFFFF",
    paddingBottom: "10px"
  },
  summaryValue: {
    fontWeight: 400,
    fontFamily: "Roobert",
    fontSize: "14px",
    letterSpacing: "0px"
  },
  buttonLoading: {
    position: 'absolute',
    visibility: 'visible',
    display: 'flex',
    right: 10
  },
}));

function ConfigurePage() {
  const classes = useStyles();
  const isXSmallWidth = useXSmallWidthMediaQuery();
  const isXSmallHeight = useXSmallHeightMediaQuery();
  const history = useHistory();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  
  const { tt, st, ht, hp, pp, cf } = useConfig();
  const { id, brand, oilName, oilType, oilStrain, terpenes, htStep, hpStep, ppStep, cfStep, isLocked, update, encodeRGB, sharedUrl, importFromLocalStorage } = useSetting();
  const { email } = useAuth();
  const isAdvMode = process.env.REACT_APP_ENV === "adv";

  useEffectOnce(() => {
    if (email === undefined || !isValidEmail(email)) {
      history.push("/auth")
      return
    } 
    // Load from local storage to avoid loosing data after refreshing
    importFromLocalStorage((data) => {
      if (data === undefined) {
        history.push("/auth")
        return
      }
      const { id } = data
      if (id === null) {
        history.push("/user")
        return
      }
    })
  })

  const handleApplyToPod = () => {
    history.push(`/activation?rgbks=${encodeRGB(email)}`);
  };
  
  return (
    <Layout title="Configure Pod"
            showBack={true}>
      <Helmet>
        <title>Configure</title>
      </Helmet>
      <Box>
        <Box mt={isXSmallHeight ? 1 : 3} className={classes.summary}>
          <Box display="flex" flexDirection="row" className={classes.summaryTitleBox}>
            <Box display="flex" flexDirection="row" flexGrow={1} flex={1} alignItems="center">
              <Box ml={isXSmallWidth ? 1 : 2} height="22px">
                <Typography className={classes.summaryTitle}>
                  Oil Name: {oilName ?? "N/A"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.summaryBox}>
            <Box className={classes.rowsContainer}>
              <Box className={classes.fullRow}>
                <Box ml={isXSmallWidth ? 1 : 2} mt="10px" display="flex" flexDirection="row">
                  <Box flexGrow={1} flex={1}>
                    <Typography className={classes.summaryValue} style={{ color: "#7E7E7E" }}>
                      Oil Type
                    </Typography>
                  </Box>
                  <Box ml={isXSmallWidth ? 1 : 2} mr={isXSmallWidth ? 1 : 2} flexShrink={1}>
                    <Typography className={classes.summaryValue}>
                      {oilType ?? "N/A"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.fullRow}>
                <Box ml={isXSmallWidth ? 1 : 2} mt="5px" display="flex" flexDirection="row">
                  <Box flexGrow={1} flex={1}>
                    <Typography className={classes.summaryValue} style={{ color: "#7E7E7E" }}>
                      Oil Strain
                    </Typography>
                  </Box>
                  <Box ml={isXSmallWidth ? 1 : 2} mr={isXSmallWidth ? 1 : 2} flexShrink={1}>
                    <Typography className={classes.summaryValue}>
                      {oilStrain ?? "N/A"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              { isAdvMode ? <Box /> : (
                <Box className={classes.fullRow}>
                  <Box ml={isXSmallWidth ? 1 : 2} mt="5px" display="flex" flexDirection="row">
                    <Box flexGrow={1} flex={1}>
                      <Typography className={classes.summaryValue} style={{ color: "#7E7E7E" }}>
                        Terpenes
                      </Typography>
                    </Box>
                    <Box ml={isXSmallWidth ? 1 : 2} mr={isXSmallWidth ? 1 : 2} flexShrink={1}>
                      <Typography className={classes.summaryValue}>
                        {terpenes ?? 0}%
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        { isAdvMode ? <Box /> : (
          <Box mt={1}>
            <Typography className={classes.content}>
              Adjust the following configurations to find the perfect taste for your oil.
            </Typography>
          </Box>
        )}
        <Box mt={1} width="100%">
          {/* Heating Time */}
          <Box className={classes.card} style={{ paddingTop: "5px", paddingBottom: "10px" }}>
            <Box ml={1} mr={1}>
              <Box className={classes.justifyContainer}>
                <Typography className={classes.cardTitle}>
                  {isAdvMode ? "Heating Time" : tt[0]}
                </Typography>
                <Typography className={classes.sliderValue} align="right">
                  { isAdvMode ? `${(1.0 + 0.1 * (htStep ?? 0)).toFixed(1)}s` : `${(htStep ?? 0) + 1}` }
                </Typography>
              </Box>
              { isAdvMode ? <Box /> : (
                <Typography className={classes.cardContent}>
                  {st[0]}
                </Typography>
              )}
              <Box mt={1} mb={1} className={classes.slider}>
                <CustomSlider
                  type='b2r'
                  value={(htStep ?? 0) + 1}
                  min={1}
                  max={isAdvMode ? 16 : 9}
                  setValue={(newValue) => {
                    const htStep = newValue as number - 1
                    update({ htStep } as OilHeatSettingsData)
                }}/>
              </Box>
              { isAdvMode ? <Box /> : (
                <Box mt={isXSmallHeight ? 0 : 1} className={classes.justifyContainer}>
                  <Typography variant="body2" className={classes.sliderFooter}>
                    lower
                  </Typography>
                  <Typography variant="body2" align="right" className={classes.sliderFooter}>
                    higher
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          {/* Heating Power */}
          <Box mt={1} className={classes.card} style={{ paddingTop: "5px", paddingBottom: "10px" }}>
            <Box ml={1} mr={1}>
              <Box className={classes.justifyContainer}>
                <Typography className={classes.cardTitle}>
                  {isAdvMode ? "Heating Power" : tt[1]}
                </Typography>
                <Typography className={classes.sliderValue} align="right">
                  { isAdvMode ? `${(7.25 + 0.25 * (hpStep ?? 0)).toFixed(2)}W` : `${(hpStep ?? 0) + 1}` }
                </Typography>
              </Box>
              { isAdvMode ? <Box /> : (
                <Typography className={classes.cardContent}>
                  {st[1]}
                </Typography>
              )}
              <Box mt={1} mb={1} className={classes.slider}>
                <CustomSlider
                  type='b2r'
                  value={(hpStep ?? 0) + 1}
                  min={1}
                  max={isAdvMode ? 16 : 9}
                  setValue={(newValue) => {
                    const hpStep = newValue as number - 1
                    update({ hpStep } as OilHeatSettingsData)
                }}/>
              </Box>
              { isAdvMode ? <Box /> : (
                <Box mt={isXSmallHeight ? 0 : 1} className={classes.justifyContainer}>
                  <Typography variant="body2" className={classes.sliderFooter}>
                    lower
                  </Typography>
                  <Typography variant="body2" align="right" className={classes.sliderFooter}>
                    higher
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          {/* Plateau Power */}
          <Box mt={1} className={classes.card} style={{ paddingTop: "5px", paddingBottom: "10px" }}>
            <Box ml={1} mr={1}>
              <Box className={classes.justifyContainer}>
                <Typography className={classes.cardTitle}>
                  {isAdvMode ? "Plateau Power" : tt[2]}
                </Typography>
                <Typography className={classes.sliderValue} align="right">
                  { isAdvMode ? `${(5 + 5 * (ppStep ?? 0)).toFixed(0)}%` : `${(ppStep ?? 0) + 1}` }
                </Typography>
              </Box>
              { isAdvMode ? <Box /> : (
                <Typography className={classes.cardContent}>
                  {st[2]}
                </Typography>
              )}
              <Box mt={1} mb={1} className={classes.slider}>
                <CustomSlider
                  type='b2r'
                  value={(ppStep ?? 0) + 1}
                  min={1}
                  max={isAdvMode ? 16 : 9}
                  setValue={(newValue) => {
                    const ppStep = newValue as number - 1
                    update({ ppStep } as OilHeatSettingsData)
                }}/>
              </Box>
              { isAdvMode ? <Box /> : (
                <Box mt={isXSmallHeight ? 0 : 1} className={classes.justifyContainer}>
                  <Typography variant="body2" className={classes.sliderFooter}>
                    lower
                  </Typography>
                  <Typography variant="body2" align="right" className={classes.sliderFooter}>
                    higher
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          {/* Cooling Factor */}
          <Box mt={1} className={classes.card} style={{ paddingTop: "5px", paddingBottom: "10px" }}>
            <Box ml={1} mr={1}>
              <Box className={classes.justifyContainer}>
                <Typography className={classes.cardTitle}>
                  {isAdvMode ? "Cooling Factor" : tt[3]}
                </Typography>
                <Typography className={classes.sliderValue} align="right">
                  { isAdvMode ? `${(0.1 + 0.1 * (cfStep ?? 0)).toFixed(1)}` : `${(cfStep ?? 0) + 1}` }
                </Typography>
              </Box>
              { isAdvMode ? <Box /> : (
                <Typography className={classes.cardContent}>
                  {st[3]}
                </Typography>
              )}
              <Box mt={1} mb={1} className={classes.slider}>
                <CustomSlider
                  type='b2r'
                  value={(cfStep ?? 0) + 1}
                  min={1}
                  max={isAdvMode ? 32 : 9}
                  setValue={(newValue) => {
                    const cfStep = newValue as number - 1
                    update({ cfStep } as OilHeatSettingsData)
                }}/>
              </Box>
              { isAdvMode ? <Box /> : (
                <Box mt={isXSmallHeight ? 0 : 1} className={classes.justifyContainer}>
                  <Typography variant="body2" className={classes.sliderFooter}>
                    lower
                  </Typography>
                  <Typography variant="body2" align="right" className={classes.sliderFooter}>
                    higher
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box height={isXSmallHeight ? 64 : 88} />
      <Box height={isXSmallHeight ? 52 : 68} className={`${classes.bottomBarBackground} ${classes.bottomBar}`} />
      <Box className={classes.bottomBar} mt={isXSmallHeight ? 1 : 2} mb={isXSmallHeight ? 1 : 2}>
        <Box mr={1} style={{ flexBasis: "75%" }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleApplyToPod}
            className={classes.rainbow}
            disableElevation
            disableFocusRipple
            disableRipple
          >
            apply to pod
          </Button>
        </Box>
        <Box style={{ flexGrow: 1 }}>
          <Button
            variant="contained"
            fullWidth
            className={classes.saveButton}
            disableElevation
            disableFocusRipple
            disableRipple
            disabled={isSaving}
            onClick={() => {
              setIsSaving(true)
              const htValue = isAdvMode ? htStep : ht[htStep]
              const hpValue = isAdvMode ? hpStep : hp[hpStep]
              const ppValue = isAdvMode ? ppStep : pp[ppStep]
              const cfValue = isAdvMode ? cfStep : cf[cfStep]
              addLog(
                id,
                email,
                brand,
                oilName,
                oilType,
                oilStrain,
                `${terpenes?.toFixed(0) ?? 0}%`,
                `${htStep + 1}=${(1 + 0.1 * htValue).toFixed(1)}s(${htValue})`,
                `${hpStep + 1}=${(7.25 + 0.25 * hpValue).toFixed(2)}w(${hpValue})`,
                `${ppStep + 1}=${5 + 5 * ppValue}%(${ppValue})`,
                `${cfStep + 1}=${(0.1 + 0.1 * cfValue).toFixed(1)}(${cfValue})`,
                `${window.location.origin}${sharedUrl()}`,
                "save",
                isLocked ? "1" : ""
              ).then(() => {
                setIsSaving(false)
                let isNew = false;
                update({ isNew } as OilHeatSettingsData)
                history.push("/user");
              })
            }}
          >
            save
            {
              isSaving && 
              (<Box className={classes.buttonLoading}>
                <CircularProgress size={20}/>
              </Box>)
            }
          </Button>
        </Box>
      </Box>
    </Layout>
  );
}

export default ConfigurePage;