import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Theme,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress
} from "@material-ui/core";
import useSetting from "../hooks/use-setting";
import useConfig from "../hooks/use-config";
import Layout from "../components/layouts";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import useXSmallWidthMediaQuery from "../hooks/use-xsmall-width-mq";
import useXSmallHeightMediaQuery from "../hooks/use-xsmall-height-mq";
import { Helmet } from "react-helmet";
import { useEffectOnce } from "react-use";
import { Log, getLogs } from "../services/logs";
import moment from "moment-timezone";
import { isValidEmail } from "../helpers/validateEmail";
import useAuth from "../hooks/use-auth";

const useStyles = makeStyles((theme: Theme) => ({
  "@global": {
    "@keyframes rainbowGradient": {
      "0%": {
        backgroundPosition: "0% 0%"
      },
      "50%": {
        backgroundPosition: "50% 0%"
      },
      "100%": {
        backgroundPosition: "100% 0%"
      }
    }
  },
  title: {
    fontWeight: 400,
    fontFamily: "Reckless",
    fontSize: "20px",
    lineHeight: 1.2,
    textAlign: "left"
  },
  content: {
    fontWeight: 400,
    fontFamily: "Roobert",
    fontSize: "14px",
    letterSpacing: "0.75px",
  },
  card: {
    borderRadius: "5px",
    overflow: "hidden",
    background: "#FFFFFF",
    paddingTop: "10px",
    paddingBottom: "10px"
  },
  editButton: {
    width: "36px",
    height: "36px",
    fontWeight: 600,
    fontFamily: "Roobert",
    fontSize: "12px",
    letterSpacing: "1.5px",
  },
  urlBox: {
    display: "flex",
    flexDirection: "row",
    borderRadius: "5px",
    overflow: "hidden",
    height: "40px",
    background: "#FFFFFF",
    alignItems: "center",
    justifyContent: "center"
  },
  url: {
    fontWeight: 400,
    fontFamily: "Roobert",
    fontSize: "14px",
    lineHeight: 1.25,
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  urlButton: {
    fontWeight: 600,
    fontFamily: "Roobert",
    fontSize: "12px",
    letterSpacing: "0.5px",
    [theme.breakpoints.down(375)]: {
      fontSize: "12px",
      letterSpacing: "0px",
    },
    height: 24,
    textAlign: "center",
    borderRadius: "50px",
    textTransform: "uppercase",
    color: "#1E1E1E",
    background: "#E4E4E4",
  },
  rowsContainer: {
    display: "flex",
    flexWrap: "wrap"
  },
  fullRow: {
    flexBasis: "100%"
  },
  halfRowLeft: {
    flexBasis: "50%",
    paddingRight: theme.spacing(1)
  },
  halfRowRight: {
    flexBasis: "50%",
    paddingLeft: theme.spacing(1)
  },
  rainbow: {
    fontWeight: 600,
    fontFamily: "Roobert",
    fontSize: "12px",
    letterSpacing: "1px",
    [theme.breakpoints.down(375)]: {
      fontSize: "12px",
      letterSpacing: "0px",
    },
    height: 36,
    background: "linear-gradient(90deg, #FF0000, #FFEA29, #55FF5C, #00C2FF, #FA00FF, #FF0000)",
    backgroundSize: "1000%",
    animation: "rainbowGradient 2s linear infinite",
    borderRadius: "50px",
    textTransform: "uppercase",
  },
  fillHeight: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flex: 1,
    justifyContent: "center"
  },
  bottomBar: {
    position: "fixed",
    left: "0",
    right: "0",
    bottom: "0",
    transform: "translate(calc(50vw - 50%))",
    maxWidth: '100vw',
    minWidth: '320px',
    [theme.breakpoints.up("sm")]: {
      maxWidth: '600px',
    },
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down(375)]: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    display: "flex",
    flexWrap: "wrap",
  },
  bottomBarBackground: {
    borderTop: "1px solid #DDD",
    backgroundColor: "#F3F0E9"
  },
  button: {
    fontWeight: 600,
    fontFamily: "Roobert",
    fontSize: "12px",
    letterSpacing: "1px",
    [theme.breakpoints.down(375)]: {
      fontSize: "12px",
      letterSpacing: "0px",
    },
    height: 36,
    textAlign: "center",
    borderRadius: "50px",
    textTransform: "uppercase"
  },
	loading: {
		display: "flex",
		flex: "1 1 auto",
		width: "100%",
		height: "320px",
		justifyContent: "center",
		alignItems: "center",
		padding: theme.spacing(3)
	},
  tableHeaderCell: {
    borderBottom: `1px solid #000000`,
    padding: "5px",
    fontSize: "12px",
    lineHeight: "16px",
    [theme.breakpoints.down(376)]: {
      fontSize: "10px",
      lineHeight: "14px",
      letterSpacing: "0px",
    },
  },
  tableRowCell: {
    padding: "5px",
    fontSize: "12px",
    [theme.breakpoints.down(376)]: {
      fontSize: "10px",
      letterSpacing: "0px",
    },
  },
  tableRowCellLocked: {
    padding: "5px",
    fontSize: "12px",
    fontWeight: 600,
    [theme.breakpoints.down(376)]: {
      fontSize: "10px",
      letterSpacing: "0px",
    },
  },
  emptyState: {
    fontFamily: "Roobert",
    fontSize: "17px",
    letterSpacing: "0.75px",
    textAlign: "center",
    color: "#888"
  },
}));

function UserProfilePage() {
  const isXSmallWidth = useXSmallWidthMediaQuery();
  const isXSmallHeight = useXSmallHeightMediaQuery();
  const classes = useStyles();
  const history = useHistory();
  const { reset } = useSetting();
  const { ht, hp, pp, cf } = useConfig();
  const { email, logout } = useAuth();
  const [logs, setLogs] = useState<Log[] | null>(null);
  
  const isAdvMode = process.env.REACT_APP_ENV === "adv";

  useEffectOnce(() => {
    if (email === undefined || !isValidEmail(email)) {
      history.push("/auth");
    } else {
      getLogs(email, false).then((newLogs) => {
        setLogs(newLogs)
      })
    }
  })

  const findIndex = (values: number[], value: number) => {
    var index = values.indexOf(value)
    if (index === -1) {
      var d = 999
      for (var i = 0; i < values.length; i++) {
        const di = Math.abs(values[i] - value)
        if (di < d) {
          d = di
          index = i
        }
      }
    }
    return index
  }

  return (
    <Layout title="Oil Profiles"
            leftTitle="Locked Profiles"
            leftAction={() => {
              history.push("/locked");
            }}
            rightTitle="Sign Out"
            rightAction={() => {
              logout(() => {
                history.push("/auth");
              })
            }}>
      <Helmet>
        <title>Oil Profiles</title>
      </Helmet>
      <Box mt={isXSmallHeight ? 1 : 3} className={classes.card}>
        <Box ml={isXSmallWidth ? 1 : 2} display="flex" flexDirection="row">
          <Box flexGrow={1} flex={1}>
            <Typography className={classes.content} style={{ color: "#7E7E7E" }}>
              Email
            </Typography>
          </Box>
          <Box ml={isXSmallWidth ? 1 : 2} mr={isXSmallWidth ? 1 : 2} flexShrink={1}>
            <Typography className={classes.content}>
              {email}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box mt={2}>
        {logs === null ? (
          <Box className={classes.loading}>
            <CircularProgress />
          </Box>
        ) : (
          logs.length === 0 ? (
            <Box>
              <Box mt={isXSmallHeight ? 1 : 3}>
                <Typography className={classes.emptyState}>
                  No oil profiles yet
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography className={classes.emptyState}>
                  Start by adding a new oil profile
                </Typography>
              </Box>
            </Box>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="none" align="center" size="small" className={classes.tableHeaderCell}>🔒</TableCell>
                    <TableCell padding="none" align="left" className={classes.tableHeaderCell}>Oil</TableCell>
                    <TableCell padding="none" align="right" className={classes.tableHeaderCell}>Terp.</TableCell>
                    <TableCell padding="none" align="right" className={classes.tableHeaderCell}>Base Powr</TableCell>
                    <TableCell padding="none" align="right" className={classes.tableHeaderCell}>Powr Curv</TableCell>
                    <TableCell padding="none" align="right" className={classes.tableHeaderCell}>Long Draw</TableCell>
                    <TableCell padding="none" align="right" className={classes.tableHeaderCell}>Cons Draw</TableCell>
                    <TableCell padding="none" align="center" className={classes.tableHeaderCell}>Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    logs.map((log) => {
                      const tpStep = (log.tp / 0.01).toFixed(0)
                      const htStep = isAdvMode ? log.ht : findIndex(ht, log.ht)
                      const hpStep = isAdvMode ? log.hp : findIndex(hp, log.hp)
                      const ppStep = isAdvMode ? log.pp : findIndex(pp, log.pp)
                      const cfStep = isAdvMode ? log.cf : findIndex(cf, log.cf)
                      const date = Date.parse(log.time)
                      const isLocked = log.locked
                      const className = isLocked ? classes.tableRowCellLocked : classes.tableRowCell
                      return (
                        <TableRow hover key={log.time} onClick={() => {
                          history.push(`/profile?tp=${tpStep}&ht=${htStep}&hp=${hpStep}&pp=${ppStep}&cf=${cfStep}&bd=${encodeURIComponent(log.bd ?? "")}&on=${encodeURIComponent(log.on ?? "")}&ot=${encodeURIComponent(log.ot ?? "")}&os=${encodeURIComponent(log.os ?? "")}&id=${encodeURIComponent(log.id)}&lock=${isLocked ? "1" : ""}`);
                        }}>
                          <TableCell padding="none" size="small" className={className}>{isLocked ? "🔒" : ""}</TableCell>
                          <TableCell padding="none" className={className}>{log.on}</TableCell>
                          <TableCell padding="none" align="right" className={className} style={{ fontFamily: "menlo"}}>{`${(log.tp * 100).toFixed(0)}%`}</TableCell>
                          <TableCell padding="none" align="right" className={className} style={{ fontFamily: "menlo"}}>
                            { isAdvMode ? `${(1.0 + 0.1 * (htStep ?? 0)).toFixed(1)}s` : `${(htStep ?? 0) + 1}` }
                          </TableCell>
                          <TableCell padding="none" align="right" className={className} style={{ fontFamily: "menlo"}}>
                            { isAdvMode ? `${(7.25 + 0.25 * (hpStep ?? 0)).toFixed(2)}W` : `${(hpStep ?? 0) + 1}` }
                          </TableCell>
                          <TableCell padding="none" align="right" className={className} style={{ fontFamily: "menlo"}}>
                            { isAdvMode ? `${(5 + 5 * (ppStep ?? 0)).toFixed(0)}%` : `${(ppStep ?? 0) + 1}` }
                          </TableCell>
                          <TableCell padding="none" align="right" className={className} style={{ fontFamily: "menlo"}}>
                            { isAdvMode ? `${(0.1 + 0.1 * (cfStep ?? 0)).toFixed(1)}` : `${(cfStep ?? 0) + 1}` }
                          </TableCell>
                          <TableCell padding="none" align="center" className={className} style={{ fontFamily: "menlo"}}>{moment(date).tz(moment.tz.guess()).format("MM/DD/YY[\n]HH:mmz")}</TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          )
        )}
      </Box>
      <Box height={isXSmallHeight ? 64 : 88} />
      <Box height={isXSmallHeight ? 52 : 68} className={`${classes.bottomBarBackground} ${classes.bottomBar}`} />
      <Box className={classes.bottomBar} mt={isXSmallHeight ? 1 : 2} mb={isXSmallHeight ? 1 : 2}>
        <Box className={classes.fullRow}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.button}
            disableElevation
            disableFocusRipple
            disableRipple
            onClick={() => {
              reset(() => {
                history.push("/oil");
              })
            }}
          >
            new oil profile
          </Button>
        </Box>
      </Box>
    </Layout>
  );
}

export default UserProfilePage;