import { Box, Button, makeStyles, styled, Typography, Theme } from "@material-ui/core";
import React, { useState } from "react";
import ActivationSequence from "../components/ActivationSequence";
import Alert from "@material-ui/lab/Alert";
import { useHistory, useLocation } from "react-router-dom";
import {  } from "react-router-dom";
import Layout from "../components/layouts";
import * as queryString from "query-string";
import { useEffectOnce } from "react-use";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme: Theme) => ({
  "@global": {
    "@keyframes rainbowGradient": {
      "0%": {
        backgroundPosition: "0% 0%"
      },
      "50%": {
        backgroundPosition: "50% 0%"
      },
      "100%": {
        backgroundPosition: "100% 0%"
      }
    }
  },
  title: {
    fontWeight: 400,
    fontFamily: "Reckless",
    fontSize: "20px",
    lineHeight: "24px"
  },
  rainbow: {
    fontWeight: 600,
    fontFamily: "Roobert",
    fontSize: "12px",
    letterSpacing: "1px",
    height: 36,
    minWidth: 320 - theme.spacing(6),
    maxWidth: theme.breakpoints.width("sm"),
    background: "linear-gradient(90deg, #FF0000, #FFEA29, #55FF5C, #00C2FF, #FA00FF, #FF0000)",
    backgroundSize: "1000%",
    animation: "rainbowGradient 2s linear infinite",
    borderRadius: "50px",
    textTransform: "uppercase",
  },
  fillHeight: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flex: 1,
    justifyContent: "center"
  },
  bottomBar: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  button: {
    fontWeight: 600,
    fontFamily: "Roobert",
    fontSize: "12px",
    letterSpacing: "1px",
    height: 36,
    textAlign: "center",
    borderRadius: "50px",
    textTransform: "uppercase"
  }
}));

const CustomVideo = styled("video")(() => ({
  width: "100%",
  height: "auto !important",
  "@media (max-height: 500px)": {
    maxHeight: "30vh"
  }
}));

const DesktopContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    borderRadius: "8px",
    background: theme.palette.grey[100],
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
    position: "relative",
    margin: "0 auto",
    maxWidth: "480px",
    width: "100%",
    height: "min(675px, calc(100vh - var(--header-height) - 48px))",
    marginTop: theme.spacing(4),
    overflow: "hidden"
  }
}));

const BackgroundCountdownTypography = styled(Typography)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  fontFamily: "'Roboto Mono', monospace",
  fontSize: "min(100vh, 700px)",
  lineHeight: 0.85,
  textAlign: "center",
  color: theme.palette.grey[50],
  zIndex: -1,
  userSelect: "none",
  [theme.breakpoints.up("sm")]: {
    fontSize: "min(85vh, 700px)"
  }
}));

const CountdownTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "'Roboto Mono', monospace",
  fontSize: "105px",
  lineHeight: "105px",
  textAlign: "center",
  color: theme.palette.primary.main,
  userSelect: "none"
}));

type ActivationUnlockState = "INITIAL" | "COUNTDOWN" | "SEQUENCE";

const COUNTDOWN_TOTAL_TIME = 3;

function ActivationPage() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const redirectTo = params.redirectTo as string | null
  const rgbks = params.rgbks as string
  // Pod details & sequence
  const colors: { [index: string]: string; } = {
    "R": "#FF0000", 
    "G": "#00FF00",
    "B": "#0000FF",
    "K": "#000000"
  };
  var podActivation = rgbks.split("").flatMap(color => (colors[color] ?? [])).map(color => ({ "color": color, "time": 133}));
  podActivation.unshift({ "color": "#000000", "time": 500})
  podActivation.push({ "color": "#000000", "time": 500})

  // UI Step & states
  const [step, setStep] = useState<ActivationUnlockState>("COUNTDOWN");
  const [countdownTime, setCountdownTime] = useState<number>(
    COUNTDOWN_TOTAL_TIME
  );

  const handleCountdownOver = () => {
    setCountdownTime(COUNTDOWN_TOTAL_TIME);
    setStep("SEQUENCE");
  };

  // Decreate countdown after 1 second. If countdown is over, invoke callback
  const decreaseCountdownTime = (newCountdownTime: number) => {
    if (newCountdownTime >= 0) {
      setTimeout(() => {
        setCountdownTime(newCountdownTime);
        decreaseCountdownTime(newCountdownTime - 1);
      }, 1000);
    } else {
      handleCountdownOver();
    }
  };

  // Start activation: Show countdown
  const startActivation = () => {
    setStep("COUNTDOWN");
    decreaseCountdownTime(COUNTDOWN_TOTAL_TIME - 1);
  };
  
  useEffectOnce(() => {
    startActivation()
  })

  const handleActivationSequenceComplete = () => {
    if (redirectTo == null) {
      window.history.back()
    } else {
      history.push(redirectTo);
    }
  };

  // Invalid activation
  if (!podActivation) {
    return (
      <Layout>
        <Helmet>
          <title>Apply To Pod</title>
        </Helmet>
        <Box marginY={2}>
          <Alert severity="error">
            Something went wrong trying to load the activation sequence. Try
            reloading the page.
          </Alert>
        </Box>

        <Button
          variant="contained"
          className="bold"
          onClick={() => window.location.reload()}
          fullWidth
        >
          Reload
        </Button>
      </Layout>
    );
  }

  // Initial screen
  if (step === "INITIAL") {
    return (
      <Layout>
        <Box mt={1} mb={1}>
            <Typography className={classes.title}>
              Place your Battery directly on this screen.
            </Typography>
        </Box>
        <Box mt={1} mb={1}>
            <Typography className={classes.title}>
              Rainbow facing up.
            </Typography>
          </Box>
        <Box marginX="auto" className={classes.fillHeight}>
          <CustomVideo
            controls={false}
            preload="auto"
            muted
            playsInline
            autoPlay
            loop
            // @ts-ignore
            disableRemotePlayback={true}
            poster="https://image.mux.com/9Jw3IWRgsyfbto1VbUmXZX02XSGDzDoml/thumbnail.jpg?time=0"
          >
            <source
              src="https://www.datocms-assets.com/15671/1611859419-step3-screen-placement-export-loop.mp4"
              type={"video/mp4"}
            />
          </CustomVideo>
        </Box>
        <Box mt={1}>
          <Box width="100%" className={classes.bottomBar}>
            <Box mb={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={startActivation}
                className={classes.rainbow}
                disableElevation
                disableFocusRipple
                disableRipple
              >
                i’m ready, light me up
              </Button>
            </Box>
            <Button
              variant="contained"
              className={classes.button}
              fullWidth
              onClick={() => window.history.back()}
              disableElevation
              disableFocusRipple
              disableRipple
            >
              cancel
            </Button>
          </Box>
        </Box>
      </Layout>
    );
  }

  // Countdown screen
  if (step === "COUNTDOWN") {
    return (
      <DesktopContainer>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          padding={3}
          paddingBottom={5}
          position={{ xs: "fixed", sm: "absolute" }}
          top={0}
          left={0}
          width="100%"
          height="100%"
          zIndex={9000}
          bgcolor="grey.200"
        >
          <Box mb={2}>
            <CountdownTypography>{countdownTime}</CountdownTypography>
            <BackgroundCountdownTypography>
              {countdownTime}
            </BackgroundCountdownTypography>
          </Box>
          <Typography variant="h5" style={{ textAlign: "center", userSelect: "none" }}>
            Do not move the battery until the end of the light show.
          </Typography>
        </Box>
      </DesktopContainer>
    );
  }

  // Sequence
  if (step === "SEQUENCE") {
    return (
      <DesktopContainer>
        <ActivationSequence
          podActivation={podActivation}
          onComplete={handleActivationSequenceComplete}
        />
      </DesktopContainer>
    );
  }

  // Shouldn't come here
  return (
    <Layout>
      <Box marginY={2}>
        <Alert severity="error">
          Something went wrong trying to load the activation sequence. Try
          reloading the page.
        </Alert>
      </Box>

      <Button
        variant="contained"
        className="bold"
        onClick={() => window.location.reload()}
        fullWidth
      >
        Reload
      </Button>
    </Layout>
  );
}

export default ActivationPage