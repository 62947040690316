import { Slider } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

type CustomSliderType = 'y2r' | 'b2r'

function CustomSliderThumb(props: any) {
  return (
    <span {...props}>
      <span className="bar" />
      <span className="bar" />
    </span>
  );
}

const CustomSliderY2R = withStyles({
  root: {
    height: 4,
    padding: '0 0',
  },
  thumb: {
    height: 36,
    width: 36,
    backgroundColor: '#fff',
    boxShadow: 'rgba(0,0,0,0.15) 0 3px 13px 0px',
    marginTop: -15,
    marginLeft: -18,
    '&:focus, &:hover': {
      boxShadow: 'rgba(0,0,0,0.15) 0 3px 13px 0px'
    },
    '& .bar': {
      // display: inline-block !important;
      height: 12,
      width: 1,
      backgroundColor: '#ACA9A3',
      marginLeft: 3,
      marginRight: 3,
    },
  },
  track: {
    opacity: 0
  },
  rail: {
    height: 6,
    opacity: 1,
    backgroundImage: `linear-gradient(.25turn, ${["#FFC700", "#FF360A"].join(", ")})`,
    borderRadius: 3
  },
  mark: {
    opacity: 1,
    backgroundColor: '#000',
    height: 6,
    width: 1,
    marginTop: -12,
    marginLeft: -0.5,
  },
  markActive: {
    opacity: 1,
    backgroundColor: '#000',
    height: 6,
    width: 1,
    marginTop: -12,
    marginLeft: -0.5,
  },
})(Slider);

const CustomSliderB2R = withStyles({
  root: {
    height: 4,
    padding: '0 0',
  },
  thumb: {
    height: 36,
    width: 36,
    backgroundColor: '#fff',
    boxShadow: 'rgba(0,0,0,0.15) 0 3px 13px 0px',
    marginTop: -15,
    marginLeft: -18,
    '&:focus, &:hover': {
      boxShadow: 'rgba(0,0,0,0.15) 0 3px 13px 0px'
    },
    '& .bar': {
      // display: inline-block !important;
      height: 12,
      width: 1,
      backgroundColor: '#ACA9A3',
      marginLeft: 3,
      marginRight: 3,
    },
  },
  track: {
    opacity: 0
  },
  rail: {
    height: 6,
    opacity: 1,
    backgroundImage: `linear-gradient(.25turn, ${["#11B4E8", "#FFEC52", "#FF5C00"].join(", ")})`,
    borderRadius: 3,
  },
  mark: {
    opacity: 1,
    backgroundColor: '#000',
    height: 6,
    width: 1,
    borderRadius: 4,
    marginTop: -12,
    marginLeft: -0.5,
  },
  markActive: {
    opacity: 1,
    backgroundColor: '#000',
    height: 6,
    width: 1,
    borderRadius: 4,
    marginTop: -12,
    marginLeft: -0.5,
  },
})(Slider);

const makeCustomSlider = (type: CustomSliderType, value: number, min: number, max: number, setValue: (value: number | number[]) => void) => {
  if (type === 'y2r') {
    return (
      <CustomSliderY2R
        value={value}
        valueLabelDisplay="off"
        ThumbComponent={CustomSliderThumb}
        onChange={(e, v) => {
          setValue(v)
        }}
        marks
        step={1}
        min={min}
        max={max}
      />
    )
  } else  {
    return (
      <CustomSliderB2R
        value={value}
        valueLabelDisplay="off"
        ThumbComponent={CustomSliderThumb}
        onChange={(e, v) => {
          setValue(v)
        }}
        marks
        step={1}
        min={min}
        max={max}
      />
    )
  }
}

type Props = {
  type: CustomSliderType;
  value: number;
  min: number;
  max: number;
  setValue: (value: number | number[]) => void
};

export default function CustomSlider({ type, value, min, max, setValue }: Props) {
  return makeCustomSlider(type, value, min, max, setValue)
}