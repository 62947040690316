import React from "react";
import { useEffectOnce, useSetState } from "react-use";
import { ConfigData } from "../types/airgraft";

export interface ConfigContextType extends ConfigData {
  
}

export const ConfigContext = React.createContext<ConfigContextType>({
  tt: [],
  st: [],
  ht: [],
  hp: [],
  pp: [],
  cf: []
});

const initState = {
  tt: [],
  st: [],
  ht: [],
  hp: [],
  pp: [],
  cf: []
};

export default function ConfigProvider({ children }: { children: any }) {

  const [state, setState] = useSetState<ConfigData>(initState);

  const loadConfig = async () => {
    const response = await fetch(`${process.env.REACT_APP_LOG_URL}?config=1`);
    if (!response?.ok) {
      return;
    }
    const json = await response.json()
    const data = json as ConfigData
    setState(data)
  }

  useEffectOnce(() => {
    loadConfig()
  })

  return (
    <ConfigContext.Provider value={{
      ...state
    }}>
      {children}
    </ConfigContext.Provider>
  );
}
