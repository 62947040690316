import React, { useEffect, useRef } from "react";
import { OilHeatSettingsData } from "../types/airgraft";
import { useSetState } from "react-use";
import * as queryString from "query-string";
import { validateQueryParams } from "../helpers/validateQueryParams";
import { addLog } from "../services/logs";
import useConfig from "../hooks/use-config";
import { v4 as uuidv4 } from 'uuid';

const stub = (): never => {
  throw new Error("You forgot to wrap your component in <SettingProvider>.");
};

export interface SettingContextType extends OilHeatSettingsData {
  reset: (completion?: ((data?: OilHeatSettingsData) => void)) => void;
  update: (data: OilHeatSettingsData, completion?: ((data?: OilHeatSettingsData) => void)) => void;
  encodeRGB: (email: string) => string;
  sharedUrl: () => string;
  importFromUrl: (completion?: ((data?: OilHeatSettingsData) => void)) => void;
  importFromLocalStorage: (completion?: ((data?: OilHeatSettingsData) => void)) => void;
  exportToLocalStorage: (data?: OilHeatSettingsData) => void;
}

const DefaultHTStep = 7
const DefaultHPStep = 7
const DefaultPPStep = 7
const DefaultCFStep = 15

export const SettingContext = React.createContext<SettingContextType>({
  isNew: true,
  id: uuidv4(),
  brand: undefined,
  oilName: undefined,
  oilType: undefined,
  oilStrain: undefined,
  terpenes: 0,
  htStep: DefaultHTStep,
  hpStep: DefaultHPStep,
  ppStep: DefaultPPStep,
  cfStep: DefaultCFStep,
  isLocked: false,
  update: stub,
  reset: stub,
  encodeRGB: stub,
  sharedUrl: stub,
  importFromUrl: stub,
  importFromLocalStorage: stub,
  exportToLocalStorage: stub
});

var setStateCompletionCallback: ((data?: OilHeatSettingsData) => void) | undefined

export default function SettingProvider({ children }: { children: any }) {

  const initState = {
    isNew: true,
    id: uuidv4(),
    brand: undefined,
    oilName: undefined,
    oilType: undefined,
    oilStrain: undefined,
    terpenes: 0,
    htStep: DefaultHTStep,
    hpStep: DefaultHPStep,
    ppStep: DefaultPPStep,
    cfStep: DefaultCFStep,
    isLocked: false,
  };
  const [state, setState] = useSetState<OilHeatSettingsData>(initState);
  const { ht, hp, pp, cf } = useConfig();

  const isFirstTime = useRef(true);
  useEffect(() => {
    if (isFirstTime.current) {
      isFirstTime.current = false
    } else {
      console.log("did update state")
      exportToLocalStorage(state)
      setStateCompletionCallback && setStateCompletionCallback(state)
      setStateCompletionCallback = undefined
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const reset = (completion?: ((data?: OilHeatSettingsData) => void)) => {
    setStateCompletionCallback = completion
    setState(initState)
  };

  const update = (data: OilHeatSettingsData, completion?: ((data?: OilHeatSettingsData) => void)) => {
    console.log("will update state", data)
    setStateCompletionCallback = completion
    setState({
      ...state,
      ...data
    });
  };

  const sharedUrl = () => {
    return `/profile?tp=${state.terpenes?.toFixed(0) ?? 0}&ht=${state.htStep}&hp=${state.hpStep}&pp=${state.ppStep}&cf=${state.cfStep}&bd=${encodeURIComponent(state.brand ?? "")}&on=${encodeURIComponent(state.oilName ?? "")}&ot=${encodeURIComponent(state.oilType ?? "")}&os=${encodeURIComponent(state.oilStrain ?? "")}`
  }

  const encodeRGB = (email: string) => {
    const isAdvMode = process.env.REACT_APP_ENV === "adv";
    const htValue = isAdvMode ? state.htStep : ht[state.htStep]
    const hpValue = isAdvMode ? state.hpStep : hp[state.hpStep]
    const ppValue = isAdvMode ? state.ppStep : pp[state.ppStep]
    const cfValue = isAdvMode ? state.cfStep : cf[state.cfStep]
    console.log(`${state.htStep+1}=${(1+0.1*htValue).toFixed(1)}s(${htValue}) ${state.hpStep+1}=${(7.25+0.25*hpValue).toFixed(2)}w(${hpValue}) ${state.ppStep+1}=${5+5*ppValue}%(${ppValue}) ${state.cfStep+1}=${(0.1+0.1*cfValue).toFixed(1)}(${cfValue})`)
    addLog(
      state.id,
      email,
      state.brand,
      state.oilName,
      state.oilType,
      state.oilStrain,
      `${state.terpenes?.toFixed(0) ?? 0}%`,
      `${state.htStep + 1}=${(1 + 0.1 * htValue).toFixed(1)}s(${htValue})`,
      `${state.hpStep + 1}=${(7.25 + 0.25 * hpValue).toFixed(2)}w(${hpValue})`,
      `${state.ppStep + 1}=${5 + 5 * ppValue}%(${ppValue})`,
      `${state.cfStep + 1}=${(0.1 + 0.1 * cfValue).toFixed(1)}(${cfValue})`,
      `${window.location.origin}${sharedUrl()}`,
      "rgb",
      state.isLocked ? "1" : ""
    )
    const commandBinary = Number(0).toString(2).padStart(2, "0")
    const secretBinary = Number(27643).toString(2).padStart(16, "0")
    const cfBinary = Number(cfValue).toString(2).padStart(5, "0")
    const htBinary = Number(htValue).toString(2).padStart(4, "0")
    const hpBinary = Number(hpValue).toString(2).padStart(4, "0")
    const ppBinary = Number(ppValue).toString(2).padStart(4, "0")
    const heatingPrefBinary = Number(0).toString(2).padStart(2, "0")
    const messageBodyBinary = commandBinary + secretBinary + cfBinary + htBinary + hpBinary + ppBinary + heatingPrefBinary;
    const parityBit = () => {
      let onesCounted = 0;
      messageBodyBinary.split("").forEach((element) => {
        if (element === "1") {
          onesCounted += 1;
        }
      });
      if (onesCounted % 2 === 0) {
        return "0";
      } else {
        return "1";
      }
    }
    const messageBinary = messageBodyBinary + parityBit()
    const messageBodyTernary = Number(parseInt(messageBinary, 2)).toString(3).padStart(24, "0")
    const parityTernary = () => {
      let sum = 0;
      messageBodyTernary.split("").forEach((element) => {
        sum += parseInt(element, 10);
      });
      let remainder = sum % 3;
      let parity;
      if (remainder === 0) {
        parity = 0;
      } else {
        parity = 3 - remainder;
      }
      return "" + parity;
    }
    const messageTernary = messageBodyTernary + parityTernary()
    const naiveRgb = messageTernary.split("").map(x => ["R", "G", "B"][parseInt(x, 3)])
    const dedupRgb = () => {
      let dedupRgb = naiveRgb
      let last = "";
      for (let i = 0; i < dedupRgb.length; i++) {
        if (dedupRgb[i] === last) {
          dedupRgb[i] = "K";
        }
        last = dedupRgb[i];
      }
      return dedupRgb;
    }
    const rgbks = "RGBK" + dedupRgb().join("")
    console.log(rgbks)
    return rgbks
  };

  const importFromUrl = (completion?: ((data?: OilHeatSettingsData) => void)) => {
    // check data type
    const data = validateQueryParams(queryString.parse(window.location.search));
    if (data) {
      update(data, completion)
    }
  }

  const importFromLocalStorage = (completion?: ((data?: OilHeatSettingsData) => void)) => {
    console.log("import from local storage")
    let isNew = window.localStorage.getItem("kIsNew") === "true";
    let id = window.localStorage.getItem("kId");
    // let email = window.localStorage.getItem("kEmail");
    let brand = window.localStorage.getItem("kBrand");
    let oilName = window.localStorage.getItem("kOilName");
    let oilType = window.localStorage.getItem("kOilType");
    let oilStrain = window.localStorage.getItem("kOilStrain");
    let terpenes = parseFloat(window.localStorage.getItem("kTerpenes") ?? "0");
    let htStep = parseInt(window.localStorage.getItem("kHtStep") ?? `${DefaultHTStep}`);
    let hpStep = parseInt(window.localStorage.getItem("kHpStep") ?? `${DefaultHPStep}`);
    let ppStep = parseInt(window.localStorage.getItem("kPpStep") ?? `${DefaultPPStep}`);
    let cfStep = parseInt(window.localStorage.getItem("kCfStep") ?? `${DefaultCFStep}`);
    const data = { 
      isNew,
      id,
      brand,
      oilName,
      oilType,
      oilStrain,
      terpenes,
      htStep,
      hpStep,
      ppStep,
      cfStep
    } as OilHeatSettingsData
    update(data, completion);
  }

  const exportToLocalStorage = (data?: OilHeatSettingsData) => {
    console.log("export to local storage", data)
    const dataToSave = data ?? state
    let updateLocalStorage = (key: string, value: string | null) => {
      if (value) { window.localStorage.setItem(key, value) }
      else { window.localStorage.removeItem(key) }
    }
    updateLocalStorage("kIsNew", `${dataToSave.isNew}`)
    updateLocalStorage("kId", dataToSave.id)
    // updateLocalStorage("kEmail", dataToSave.email)
    updateLocalStorage("kBrand", dataToSave.brand ?? "")
    updateLocalStorage("kOilName", dataToSave.oilName ?? "")
    updateLocalStorage("kOilType", dataToSave.oilType ?? "")
    updateLocalStorage("kOilStrain", dataToSave.oilStrain ?? "")
    updateLocalStorage("kTerpenes", `${dataToSave.terpenes}`)
    updateLocalStorage("kHtStep", `${dataToSave.htStep}`)
    updateLocalStorage("kHpStep", `${dataToSave.hpStep}`)
    updateLocalStorage("kPpStep", `${dataToSave.ppStep}`)
    updateLocalStorage("kCfStep", `${dataToSave.cfStep}`)
  }

  return (
    <SettingContext.Provider
      value={{
        ...state,
        update,
        reset,
        encodeRGB,
        sharedUrl,
        importFromUrl,
        exportToLocalStorage,
        importFromLocalStorage
      }}
    >
      {children}
    </SettingContext.Provider>
  );
}
