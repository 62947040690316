import * as queryString from "query-string";

export async function addLog(
  id: string,
  em?: string,
  bd?: string,
  on?: string,
  ot?: string,
  os?: string,
  tp?: string,
  ht?: string,
  hp?: string,
  pp?: string,
  cf?: string,
  url?: string,
  st?: string,
  locked?: string,
  deleted?:string
): Promise<boolean> {
  const options = {
    id: id,
    em: `${em ?? ""}`,
    bd: `${bd ?? ""}`,
    on: `${on ?? ""}`,
    ot: `${ot ?? ""}`,
    os: `${os ?? ""}`,
    tp: `${tp ?? ""}`,
    ht: `${ht ?? ""}`,
    hp: `${hp ?? ""}`,
    pp: `${pp ?? ""}`,
    cf: `${cf ?? ""}`,
    url: `${url ?? ""}`,
    st: `${st ?? ""}`,
    locked: `${locked ?? ""}`,
    deleted: `${deleted ?? ""}`
  };

  const response = await fetch(
    `${process.env.REACT_APP_LOG_URL}?${queryString.stringify(options)}`, { mode: "no-cors" }
  );

  if (!response?.ok) {
    return false;
  }
  return true;
}

export type Log = {
  id: string;
  em: string;
  bd: string;
  on: string;
  ot: string;
  os: string;
  tp: number;
  ht: number;
  hp: number;
  pp: number;
  cf: number;
  time: string;
  locked: boolean;
};

export async function getLogs(email: string, onlyLocked: boolean): Promise<Log[]> {
  const options = {
    query: `em:${email.trim()},order:time,sort:desc${onlyLocked ? ",locked:1" : ""}`
  };

  const response = await fetch(
    `${process.env.REACT_APP_LOG_URL}?${queryString.stringify(options)}`
  );

  if (!response?.ok) {
    return [];
  }

  const data = await response.json();
  // console.log(JSON.stringify(data))
  const logs = data as Log[];

  return logs;
}
